import fetch from 'isomorphic-unfetch';
import { Client, Provider } from 'urql';
import cookie from 'js-cookie';
import {HN_NOTIFIER_PUBLIC_TOKEN_COOKIE_KEY_NAME, NEXT_AUTH_SESSION_TOKEN_COOKIE_KEY_NAME } from './constants';

// https://github.com/hasura/graphql-engine/issues/1912
// https://codesandbox.io/s/oxy3e



const formatToken = (token) => {
  if (typeof document !== 'undefined') {
    token = `Bearer ${token}`; // https://next-auth.js.org/configuration/options#cookies
  }
  return token;
};

const url = process.env.NEXT_PUBLIC_GRAPHQL_URL || 'https://kubernetes.docker.internal/v1/graphql';

const WithGraphQL = ({
  session,
  children,
}) => {

  let client = new Client({
    url,
    fetch,
    fetchOptions: {
      headers: {   // https://hasura.io/docs/1.0/graphql/core/auth/authorization/basics.html 
        authorization: formatToken(cookie.get(HN_NOTIFIER_PUBLIC_TOKEN_COOKIE_KEY_NAME)), 
        'X-Hasura-Role': 'anonymous' 
      },
    },
    requestPolicy: 'cache-and-network',
  });

  if(session && session.id){

    const userIdInString = session.id.toString();

    // https://hasura.io/blog/add-authentication-and-authorization-to-next-js-8-serverless-apps-using-jwt-and-graphql/
    // https://github.com/hasura/graphql-engine/blob/a7eabe034ccf15798b85e35ee62f03bcce59e0b7/community/sample-apps/nextjs-8-serverless/with-apollo-jwt/app/utils/auth.js
    // https://github.com/hasura/graphql-engine/blob/master/community/sample-apps/nextjs-8-serverless/with-apollo-jwt/app/lib/init-apollo.js
    // https://github.com/nextauthjs/next-auth/issues/563
    // https://next-auth.js.org/configuration/options#cookies
    client = new Client({
      url,
      fetch,
      fetchOptions: {
        headers: { 
          'X-Hasura-User-Id': userIdInString,
          'X-Hasura-Role': 'user',
          authorization: formatToken(cookie.get(NEXT_AUTH_SESSION_TOKEN_COOKIE_KEY_NAME)) },
      },
      requestPolicy: 'cache-and-network',
    });
  }


  return <Provider value={client}>{children}</Provider>;
};

export default WithGraphQL;
